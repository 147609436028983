import React from "react"
import Popup from "reactjs-popup"
import "../assets/css/modul.css"

import loginTenant from "../images/login_tenant.svg"
import loginLandlord from "../images/login_landlord.svg"

const PopupLoginLink = () => {
  return (
    <Popup
      trigger={
        <button className="whitespace-no-wrap text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900">
          Login
        </button>
      }
      modal
    >
      {close => (
        <div className="relative flex flex-col md:flex-row text-center">
          <button
            className="absolute top-0 right-0 m-5"
            onClick={() => {
              console.log("modal closed ")
              close()
            }}
          >
            <i className="fas fa-times text-gray-500"></i>
          </button>

          <div className="w-full md:w-1/2 bg-gray-100 p-10 md:py-20">
            <img className="h-24 mx-auto" src={loginTenant} alt="" />
            <h2 className="text-xl font-semibold text-gray-800 mt-2">
              Buyers and Tenants
            </h2>
            <p className="text-sm font-light text-gray-400 h-10">
              Find homes to buy or rent
            </p>
            <a
              href={`${process.env.GATSBY_PORTAL_URL}/login`}
              className="inline-block btn-primary mt-5"
            >
              Buyer/Tenant Login
            </a>
          </div>

          <div className="w-full md:w-1/2 p-10 md:py-20">
            <img className="h-24 mx-auto" src={loginLandlord} alt="" />
            <h2 className="text-xl font-semibold text-gray-800 mt-2">
              Agents and Landlords
            </h2>
            <p className="text-sm font-light text-gray-400 h-10">
              Automatically create Facebook and Instagram ads from your listings
            </p>
            <a
              href={`${process.env.GATSBY_CMS_URL}/login`}
              className="inline-block btn-primary-blue mt-5"
            >
              Agent/Landlord Login
            </a>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default PopupLoginLink
