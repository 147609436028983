import React from "react"
import { Link } from "gatsby"

import "../assets/css/accordian_mobile_navigation.css"

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false)
  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  )
}

const AccordionMobileNavigation = () => {
  return (
    <div className="w-full">
      <Link
        to="/agencies"
        className="ml-5 p-1 my-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
      >
        <p className="self-center text-base leading-6 font-bold text-gray-600 mt-1">
          For Agencies
        </p>
      </Link>

      <Link
        to="/developers"
        className="ml-5 p-1 my-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
      >
        <p className="self-center text-base leading-6 font-bold text-gray-600 mt-1">
        For Developers
        </p>
      </Link>

      <Accordion title="Partners">
        <div className="grid row-gap-1">
          <a
            href="/partners"
            target="_blank"
            className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
          >
            <i className="w-6 text-center text-orange-500 text-lg fas fa-bookmark mr-1"></i>
            <div className="space-y-1">
              <p className="text-base leading-6 font-medium text-gray-900">
                Partner Portal
              </p>
            </div>
          </a>
          <Link
            to="/partner-portal"
            className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
          >
            <i className="w-6 text-center text-orange-500 text-lg fas fa-chart-area mr-1"></i>
            <div className="space-y-1">
              <p className="text-base leading-6 font-medium text-gray-900">
                Become a Partner
              </p>
            </div>
          </Link>
        </div>
      </Accordion>

      <Accordion title="Resources">
        <div className="grid row-gap-1">
          <Link
            to="/blog"
            className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
          >
            <i className="w-6 text-center text-orange-500 text-lg fas fa-bookmark mr-1"></i>
            <div className="space-y-1">
              <p className="text-base leading-6 font-medium text-gray-900">
                Blog
              </p>
            </div>
          </Link>
          <Link
            to="/case-studies"
            className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
          >
            <i className="w-6 text-center text-orange-500 text-lg fas fa-binoculars mr-1"></i>
            <div className="space-y-1">
              <p className="text-base leading-6 font-medium text-gray-900">
                Case Studies
              </p>
            </div>
          </Link>
          <Link
            to="/flowfindings"
            className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
          >
            <i className="w-6 text-center text-orange-500 text-lg fas fa-chart-area mr-1"></i>
            <div className="space-y-1">
              <p className="text-base leading-6 font-medium text-gray-900">
                FlowFindings
              </p>
            </div>
          </Link>
        </div>
      </Accordion>

      <div className="flex justify-center text-orange-500 p-5">
        <a href="https://twitter.com/rentinflow" target="_blank">
          <i className="border-2 rounded-full border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-twitter"></i>
        </a>
        <a href="https://www.facebook.com/rentinflow" target="_blank">
          <i className="border-2 rounded-full border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/rentinflow" target="_blank">
          <i className="border-2 rounded-full border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/company/rentinflow" target="_blank">
          <i className="border-2 rounded-full border-orange-500 hover:bg-orange-500 hover:text-white cursor-pointer p-2 mr-1 md:mx-1 fab fa-linkedin-in"></i>
        </a>
      </div>
    </div>
  )
}

export default AccordionMobileNavigation
