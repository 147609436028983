import React from "react"
import { Link } from "gatsby"

const DropdownTenant = () => {
  const [open, setOpen] = React.useState(false)
  const drop = React.useRef(null)

  function handleClick(e) {
    if (!e.target.closest(`.${drop.current.className}`) && open) {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  })

  return (
    <div className="dropdown relative inline-block" ref={drop}>
      <button
        className="group inline-flex items-center space-x-1 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 text-gray-500"
        onClick={() => setOpen(open => !open)}
      >
        <span>Partners</span>
        <svg
          className="self-center fill-current h-4 w-4 ml-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </button>
      {open && (
        <div className="absolute -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xl overflow-hidden">
              <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-2 sm:p-4">
                <a href="/partners" target="_blank" className="-m-3 p-2 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150">
                <i className="w-5 text-center text-orange-500 text-lg fas fa-users mr-1"></i>
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Partner Portal
                    </p>
                  </div>
                </a>
                <Link
                  to="/partner-portal"
                  className="-m-3 p-2 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <i className="w-5 text-center text-orange-500 text-lg fas fa-file-alt mr-1"></i>
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Become a Partner
                    </p>
                  </div>
                </Link>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DropdownTenant
