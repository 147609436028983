import React from "react"
import { Link } from "gatsby"
import BlogArticlesNavList from "../components/blog_articles_nav_list"

const DropdownResources = () => {
  const [open, setOpen] = React.useState(false)
  const drop = React.useRef(null)

  function handleClick(e) {
    if (!e.target.closest(`.${drop.current.className}`) && open) {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  })

  return (
    <div className="dropdown relative inline-block" ref={drop}>
      <button
        className="group inline-flex items-center space-x-1 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 text-gray-500"
        onClick={() => setOpen(open => !open)}
      >
        <span>Resources</span>
        <svg
          className="self-center fill-current h-4 w-4 ml-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </button>
      {open && (
        <div className="absolute -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs overflow-hidden">
              <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-2 sm:p-4">
              
                <Link
                    to="/blog"
                    className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                  <i className="text-orange-500 text-lg fas fa-bookmark mr-1"></i>
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Blog
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                    News and insights from Flow
                    </p>
                  </div>
                  </Link>
                  <Link
                    to="/case-studies"
                    className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                  <i className="text-orange-500 text-lg fas fa-binoculars mr-1"></i>
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      Case Studies
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                    Success stories from our clients
                    </p>
                  </div>
                  </Link>
                {/* <a
                  href="#"
                  className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <i className="text-orange-500 text-lg fas fa-info-circle mr-1"></i>
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      About Flow
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Want to know more?
                    </p>
                  </div>
                </a> */}
                <Link
                  to="/flowfindings"
                  className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <i className="text-orange-500 text-lg fas fa-chart-area mr-1"></i>
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-900">
                      FlowFindings
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Tenant survey
                    </p>
                  </div>
                </Link>
              </div>
              <div className="px-5 py-5 bg-gray-100 space-y-5 sm:px-8 sm:py-8">
                <div className="space-y-4">
                  <h3 className="text-sm leading-5 tracking-wide font-medium text-gray-500 uppercase">
                    Recent Blog Posts
                  </h3>
                  <ul className="space-y-4">
                    <BlogArticlesNavList/>
                  </ul>
                </div>
                <div className="text-sm leading-5">
                  <Link
                    to="/blog"
                    className="font-medium text-orange-500 hover:text-indigo-500 transition ease-in-out duration-150"
                  >
                    View all posts →
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DropdownResources
