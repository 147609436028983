import React from "react"
import PropTypes from "prop-types"
import { globalHistory as history } from "@reach/router"
import Seo from "./seo"
import Navigation from "../components/navigation"
import CookiesModul from "./cookiesmodul"
import SaveCookieParams from "../components/save_cookie_params"
import { Link } from "gatsby"


const Layout = ({ children }) => {

const { location, navigate } = history
  const urlParams = new URLSearchParams(location.search)

  if (
    urlParams.get("action") === "refer" &&
    urlParams.get("type") === "friend"
  ) {
    navigate(
      `${process.env.GATSBY_PORTAL_URL}/register/tenant${location.search}`
    )
    return
  }

return(
  <div className="font-sans bg-white pt-16">
    <Seo title="Layout" />
    <Navigation />
    <SaveCookieParams />
    <main>{children}</main>
    <CookiesModul />
  </div>
)}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
