import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

// TODO: Limit blogs to 3

const BlogArticlesNavList = () => (
  <StaticQuery
      query={graphql`
        query {
          allStrapiBlogArticle(sort: {fields: published_at, order: DESC}, limit: 3) {
            edges {
              node {
                strapiId
                slug
                title
                published_at
                category {
                  name
                }
                image {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="">
          {data.allStrapiBlogArticle.edges.map((article, i) => {
            return <li className="text-base leading-6 truncate my-2">
              <Link to={`/blog/${article.node.slug}`} className="font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                {article.node.title}
              </Link>
            </li>
          })}
        </div>
      )}
    />
)

export default BlogArticlesNavList
