import React from "react"
import { Link } from "gatsby"

import PopupLoginLink from "../components/popup_login_link"
import PopupSignUpLink from "../components/popup_signup_link"
import DropdownLandlord from "../components/dropdown_landlord"
import DropdownTenants from "../components/dropdown_tenant"
import DropdownResources from "../components/dropdown_resources"
import DropdownPartners from "../components/dropdown_partners"
import AccordionMobileNavigation from "../components/accordian_mobile_navigation"

import logo from "../images/logo.svg"

class Navigation extends React.Component {
  state = {
    mobileNavOpen: false,
  }

  render() {
    return (
      <div className="fixed w-full top-0 bg-white z-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center border-b border-gray-200 py-3 md:justify-start md:space-x-10">
            <div className="lg:w-0 lg:flex-1">
              <Link to="/" className="flex">
                <img className="h-5 w-auto" src={logo} alt="logo" />
              </Link>
            </div>

            {/* Mobile Hamburger button */}
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                onClick={() =>
                  this.setState({ mobileNavOpen: !this.state.mobileNavOpen })
                }
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>

            <nav className="hidden md:flex space-x-10">
              <Link to="/agencies" className="inline-flex items-center space-x-1 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 text-gray-500">
                For Agencies
              </Link>

              <Link to="/developers" className="inline-flex items-center space-x-1 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 text-gray-500">
                For Developers
              </Link>

              <div className="relative">
                <DropdownPartners />
              </div>

              <div className="relative">
                <DropdownResources />
              </div>
            </nav>

            <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
              <Link to="https://app.flowliving.com/login" className="whitespace-no-wrap text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900">
                Login
              </Link>

              <Link to="/book-demo" className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border-0 border-transparent text-base leading-6 font-medium rounded-lg text-white bg-orange-500 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                Book a Demo
              </Link>
            </div>
          </div>
        </div>

        {/* Mobile */}

        <div
          className={
            this.state.mobileNavOpen
              ? "absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              : "hidden"
          }
        >
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-4 w-auto" src={logo} alt="Flow" />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      onClick={() =>
                        this.setState({
                          mobileNavOpen: !this.state.mobileNavOpen,
                        })
                      }
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>

                <AccordionMobileNavigation />

                <div className="space-y-6">
                  <span className="w-full flex justify-center rounded-md shadow-sm">
                    <Link to="/book-demo" className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border-0 border-transparent text-base leading-6 font-medium rounded-lg text-white bg-orange-500 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                      Book a Demo
                    </Link>
                  </span>
                  <div className="w-full text-center">
                    <span className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border-0border-gray-400 text-base leading-6 font-medium rounded-lg">
                      <PopupLoginLink />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Navigation
